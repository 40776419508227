import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'
import { Link } from 'gatsby'
import Theme from 'styles/Global'

const Image = styled(Plaatjie)`
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);

  /* @media (min-width: 992px) {
    height: 330px;
  } */

  @media (max-width: 991px) {
    height: 250px;
  }

  @media (max-width: 765px) {
    height: 200px;
  }

  @media (max-width: 575px) {
    height: 250px;
  }
`

const Name = styled.h3`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: ${({ theme }) => theme.font.size.huge};
  color:  ${({ theme }) => theme.color.dark};
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <section>
    <div className="container pb-5 my-lg-5">
      <div className="row">
        {fields?.members?.map((member, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="col-6 col-sm-3 mt-5">
            <Link to={member?.link?.url}>
              <Image image={member?.image} alt={member?.name || ''} />
              <Name className="text-center mt-4">{member?.name}</Name>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Team
